import {useState, VFC} from 'react';

export const WebsiteOverlay: VFC = (props) => {
    const [input, setInput] = useState('');
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    var checkCode = () => {
        if(input.toUpperCase() === "BUNGEEJUMP") {
            setShowSuccessDialog(true);
        } else {
            setShowErrorDialog(true);
        }
    }

    var funcShowSuccessDialog = () => {
        return <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Herzlichen Glückwunsch</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Super, du hast das Rätsel gelöst. Schreib uns deinen Standort und wir schicken dir deine Abschluss-Mission.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button onClick={() => setShowSuccessDialog(false)} type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Einlösen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    var funcShowErrorDialog = () => {
        return <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Falscher Code</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Da fehlen dir wohl leider noch ein paar Hinweise aber du schaffst das bestimmt.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button onClick={() => setShowErrorDialog(false)} type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Erneut Versuchen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    return <div className="video-overlay-container flex flex-col absolute w-full h-full z-10 p-10 text-white">
        <div className="flex">
            <div className="text-lg font-bold uppercase">Mission New Zealand</div>
        </div>
        <div className="flex font-bold flex-col my-auto max-w-[38rem] backdrop-blur-[40px] p-4 rounded-3xl">
            <div className="uppercase text-8xl">Mission</div>
            <div className="uppercase text-5xl mb-6">New Zealand</div>
            <div className="mb-6">Lieber Nikita,<br/><br/>
            Willkommen bei der Mission New Zealand. <br/><br/>Um dir auch die Orte zu zeigen, welche uns damals am Herzen lagen, 
            wird dich unsere Mission über die Inseln führen, wo du mit Hilfe der Karte und dem Kreuzworträstsel einen Code entschlüsseln musst, 
            welcher dir dann deine Abschluss-Mission freischalten wird. <br/>Das Freischalten der Abschluss-Mission wird dann hier möglich sein. <br/>
            Solltest du gar nicht weiterkommen, geben wir die gerne einen Tipp. Die Wörter können Orte, Treks, Strände oder Campingplätze sein.<br/>
            In dem Sinne: Gutes Gelingen!<br/><br/>Liebe Grüße von <br/><br/>Mario & Johannes
            </div>
            <div>
            <input
                type="text"
                style={styles.padding}
                value={input}
                onInput={e => setInput((e.target as HTMLInputElement).value)}
                className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                id="exampleFormControlInput1"
                placeholder="Freischaltcode"
                />
            </div>
            <button onClick={checkCode} className="mr-auto px-6 py-2 bg-white text-lg text-black uppercase rounded-lg font-bold">Unlock</button>
        </div>
        <div className="flex text-3xl gap-8 mt-auto">
        <div className="uppercase text-sm">DiesDas-Production</div>
        </div>
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

        { showErrorDialog ? funcShowErrorDialog() : null }
        { showSuccessDialog ? funcShowSuccessDialog() : null }

        </div>
    </div>;
};

var styles = {
    padding: {
      margin: "1rem 0"
    }
  }
import './VideoBackground.css';
import video from "./assets/background.mp4";

export const VideoBackground = () => {
    return <div className="video-background" dangerouslySetInnerHTML={{
        __html: `
    <video className='VideoTag' playsinline loop autoPlay muted preLoad="auto">
    <source src="${video}" type="video/mp4" />
    Your browser does not support the video tag. I suggest you upgrade your browser.
</video>            
` }}></div>;
};
